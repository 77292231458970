import image1 from "../assets/t-image 111.png";
import image2 from "../assets/t-image 222.png";
import image3 from "../assets/t-image 333.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing this Club and by choosing the right plan and program I already achieved my ideal body!",
    name: 'Dev Malhotra',
    status : 'Student'
  },
  {
    image: image2,
    review: 'Helped me a great Deal',
    name: 'Manpreet Singh',
    status: 'Music director'
  },
  {
    image : image3,
    review: 'The Staff , The Facilities and the Fellow members all are great',
    name: 'Vishesh Singhania',
    status: "Bussinessman"
  }
];
